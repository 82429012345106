<template>
  <div>
    <span class="me-1"><i class="mdi mdi-circle-medium" /></span>
    <span class="text-muted">{{ $t('common.total') }} : </span>
    <span>{{ totalCount }}</span>
  </div>
</template>

<script>
export default {
  props: {
    totalCount: Number
  }
}
</script>
